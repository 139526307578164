import React, { useState } from "react";
import Compare from "../compare/compare.component.jsx";
// import Award from "../../../assets/90-recovery.png";
import "./features.component.css";

function Features() {
  const [feature, setFeature] = useState("flex");
  const [feature1, setFeature1] = useState("none");
  const [feature2, setFeature2] = useState("none");
  const [feature3, setFeature3] = useState("none");
  const [feature4, setFeature4] = useState("none");
  const [feature5, setFeature5] = useState("none");
  const [feature6, setFeature6] = useState("none");
  return (
    <>
      <div className="features row">
        {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <img src={Award} style={{marginLeft:'20px'}} className="bestinclass mt-3"></img>
                </div> */}
        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
          <div className="features__buttons">
            <button
              className="feature-button"
              onClick={() => setFeature("flex")}
            >
              Features
            </button>
            <button
              className="compare-button"
              onClick={() => setFeature("none")}
            >
              Compare
            </button>
          </div>
          <div className="list-of-features" style={{ display: feature }}>
            <ul
              className="mt-3"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <li
                onClick={() =>
                  setFeature1(feature1 === "none" ? "block" : "none")
                }
              >
                <div className="feature__header">
                  <i className="fa fa-check-circle list-bullets" />
                  <h3>Comfortable Half In-Ear Design</h3>
                </div>
              </li>

              <li
                onClick={() =>
                  setFeature3(feature3 === "none" ? "block" : "none")
                }
              >
                <div className="feature__header">
                  <i className="fa fa-check-circle list-bullets" />
                  <h3>Smart Finding Feature</h3>
                </div>
              </li>
              <li
                onClick={() =>
                  setFeature5(feature5 === "none" ? "block" : "none")
                }
              >
                <div className="feature__header">
                  <i className="fa fa-check-circle list-bullets" />
                  <h3>3.5mm Audio Jack</h3>
                </div>
              </li>
              <li
                onClick={() =>
                  setFeature5(feature5 === "none" ? "block" : "none")
                }
              >
                <div className="feature__header">
                  <i className="fa fa-check-circle list-bullets" />
                  <h3>Lightweight & Support Android & IOS</h3>
                </div>
              </li>
            </ul>
            <ul
              className="mt-3 list-2"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <li
                onClick={() =>
                  setFeature2(feature2 === "none" ? "block" : "none")
                }
              >
                <div className="feature__header">
                  <i className="fa fa-check-circle list-bullets" />
                  <h3>In-Line HD Microphone</h3>
                </div>
              </li>
              <li
                onClick={() =>
                  setFeature4(feature4 === "none" ? "block" : "none")
                }
              >
                <div className="feature__header">
                  <i className="fa fa-check-circle list-bullets" />
                  <h3>Easy volume & Call Control Buttons</h3>
                </div>
              </li>
            </ul>
          </div>
          <div style={{ display: feature === "flex" ? "none" : "block" }}>
            <Compare />
          </div>
        </div>
      </div>
    </>
  );
}

export default Features;
