import React, { useRef, useState } from "react";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { Link } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import Features from "../home/features/features.component.jsx";
import Navbar from "../header/header.component.jsx";
import Started from "../home/how-to-get-started/howtogetstarted.component.jsx";
import Footer from "../footer/footer.component.jsx";
// import item1 from "../../assets/pet_banners/Banner 1.jpg";
// import item2 from "../../assets/pet_banners/Banner 2.jpg";
// import item3 from "../../assets/pet_banners/Banner 3.jpg";
// import item4 from "../../assets/pet_banners/Banner-4.jpg";
// import Play from "../../assets/play.png";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
// import Video from "../../assets/key-how-it-works-video.mp4";
import "../home/home.component.css";

function Home() {
  const carouselRef = useRef(null);
  const totalPages = 4;
  let resetTimeout;
  const [playvideo, setplayvideo] = useState(false);
  const handleNotFound = () => setplayvideo(false);
  const playvideopopup = () => {
    setplayvideo(true);
  };
  return (
    <div className="home">
      <Navbar />
      <div className="home__landing">
        <div className="home__para new_para_home">
          <h2 style={{ marginTop: "4rem" }}>Now Find Your Music, Literally!</h2>
          <p>
            These stylish pair of tag8 wired earphones have unique smart finding
            feature with QR code scan technology. This smart feature helps you
            to find your earphone if they are lost. The finder can directly
            connect with owner by simply scanning the tag having unique QR code.
            So, to make your listening worry free tag8 smart wired earphones is
            best choice for you. A cleverly designed 1.2m cable that promotes
            functionality while also being aesthetically beautiful enables for
            in-line microphone with mobile call & music control. This is a
            perfect gift for someone who keeps losing their earphones.
          </p>
          <div
            style={{ display: "flex", marginLeft: "40px" }}
            className="col-md-12 col-sm-12 col-12 play-btn"
          >
            <div
              _ngcontent-xsi-c153=""
              className="boxplay"
              style={{ position: "relative" }}
            >
              <Button onClick={playvideopopup} className="playbutton">
                <img
                  src={"https://storage.googleapis.com/pettag/qr/play.png"}
                  height="50px"
                  className="playbuttonimage"
                  weight="50px"
                  style={{ "border-radius": "50%", "z-index": "1" }}
                />
              </Button>
              <span className="span1"></span>
              <span className="span2"></span>
              <span className="span3"></span>
            </div>
          </div>
        </div>
        <Carousel
          className="home__carousel"
          ref={carouselRef}
          onNextEnd={({ index }) => {
            clearTimeout(resetTimeout);
            if (index + 1 === totalPages) {
              resetTimeout = setTimeout(() => {
                carouselRef.current.goTo(0);
              }, 5000); // same time
            }
          }}
          enableMouseSwipe={false}
          infinite
          showArrows={false}
          itemsToShow={1}
          enableAutoPlay
          autoPlaySpeed={5000}
        >
          <img
            className="carousel__image"
            src={
              "https://storage.googleapis.com/pettag/qr/pet_banners/Banner 1.jpg"
            }
          />
          <img
            className="carousel__image"
            src={
              "https://storage.googleapis.com/pettag/qr/pet_banners/Banner 2.jpg"
            }
          />
          <img
            className="carousel__image"
            src={
              "https://storage.googleapis.com/pettag/qr/pet_banners/Banner 3.jpg"
            }
          />
          <img
            className="carousel__image"
            src={
              "https://storage.googleapis.com/pettag/qr/pet_banners/Banner-4.jpg"
            }
          />
        </Carousel>
      </div>
      <Features />
      <Started />
      <Footer />
      <Dialog
        disableBackdropClick
        open={playvideo}
        onClose={handleNotFound}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <video
            className="videoplayer"
            height="100%"
            width="100%"
            controls
            autoplay="true"
          >
            <source src={"https://storage.googleapis.com/pettag/qr/assets/key-how-it-works-video.mp4"} type="video/mp4" />
          </video>
          <div className="row text-center">
            <div className="col">
              <button
                className="mt-4"
                style={{ width: "200px", "letter-spacing": "1px" }}
                onClick={handleNotFound}
              >
                CLOSE
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Home;
